import './App.css';
import React from 'react';
import { CODES } from './codes';

const FlightOverContext = React.createContext({
  flight_selected: null,
  selectFlight: () => {},
});

// const DEBUG = process.env.NODE_ENV === 'development';
const DEBUG = false;

const CALLSIGNES = CODES.map((item) => item.code);

// gameplay consts
const BASE_REWARD_PER_HANDLE = 10;
const TICK_PER_GROUND_OP = 30;
const BASE_REWARD_PER_TAKEOFF_LAND = 50;

// kt = nm/h
const KNOTS_TO_NM = 1 / 3600;
const KNOTS_TO_MACH = 0.00149984;

const FEETS_TO_NM = 0.000164579;

const HORIZON = 80;

const TPS_OPTS = [1, 2, 10, 60];
// const RANGES = [80, 40, 20, 10, 5];
const RANGES = [20, 10, 5, 2];

const v2length = function(o) {
  return Math.sqrt(o.x * o.x + o.y * o.y);
}
const v2dist = function(a, b) {
  return v2length({x: a.x - b.x, y: a.y - b.y});
}
const v2normalize = function(o) {
  let len = v2length(o);
  return {
    x: o.x / len,
    y: o.y / len,
  };
};


/* NOTES
 *
 * https://www.boldmethod.com/learn-to-fly/navigation/how-the-60-to-1-rule-helps-you-plan-a-perfect-descent/
 * 120 kt = 2 miles per minutes
 *
 * 3% descent rate, 1000ft에서 내려오려면 30000ft 필요 = 5nm
 */

const PLANE_SPEC = {
  'largejet': {
    divert_alt: 38000,

    cruise_alt: 40000,
    cruise_airspeed: 240,

    climb_rate: 3000 / 60,
    spawn_horizon: HORIZON,

    takeoff_airspeed: 150,
    // https://aviation.stackexchange.com/questions/35507/whats-the-acceleration-and-absolute-minimal-rwy-length-for-an-a320-during-take
    // 26 seconds to 160 kt
    takeoff_accel: 6, // kt / s*2

    airspeed_stall: 120,
    airspeed_final: 140,

    rot_mult: 2,
    descent_rate: 0.05,

    capacity: 100,
    canvas_color: 'green',

    min_runway_length: 5000,
  },

  // ref: cessna 172
  'prop': {
    divert_alt: 6500,

    // https://www.h-aviation.com/cessna-172s---skyhawk.html
    cruise_alt: 5000,
    cruise_airspeed: 100,

    climb_rate: 730 / 60,
    spawn_horizon: 12,

    takeoff_airspeed: 55,
    takeoff_accel: 2, // kt / s*2

    airspeed_stall: 50,
    airspeed_final: 65,

    rot_mult: 5,
    descent_rate: 0.1,

    capacity: 10,
    canvas_color: 'green',

    min_runway_length: 2000,
  },

  // ref: cessna 208
  // http://b.org.za/fly/C208Bproc.pdf
  'prop1': {
    divert_alt: 6500,

    // https://www.h-aviation.com/cessna-172s---skyhawk.html
    cruise_alt: 5000,
    cruise_airspeed: 180,

    climb_rate: 1200 / 60,
    spawn_horizon: 20,

    takeoff_airspeed: 55,
    takeoff_accel: 2, // kt / s*2

    airspeed_stall: 50,
    airspeed_final: 80,

    rot_mult: 5,
    descent_rate: 0.08,

    capacity: 20,
    canvas_color: 'DarkSeaGreen',

    min_runway_length: 2600,
  },
};

const START_TS = new Date("2022-01-01T06:00:00Z");

const randomRange = function(start, end) {
  const interval = end - start;
  return Math.random() * interval + start;
}
const randomChoice = function(items) {
  return items[Math.floor(randomRange(0, items.length))];
}
const randomAngle = function() {
  return randomRange(0, Math.PI * 2);
}
const randomSelect = function(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}


// sea level, FL100, FL400 두 개가 독립적이고, 사이는 선형 보간
// SE: 0 ~ 15
// FL100: 20 ~ 60
// FL400: 20 ~ 100
// refer: https://www.windy.com/
const createAir = function () {
  return {
    // sea level
    SE: {
      heading: randomAngle(),
      speed: randomRange(0, 15),
    },
    FL100: {
      heading: randomAngle(),
      speed: randomRange(20, 60),
    },
    FL400: {
      heading: randomAngle(),
      speed: randomRange(20, 100),
    },
  };
}

const lerp = function(a, b, t) {
  return a + (b - a) * t;
}

const airVectorAB = function(a, b, t) {
  let headingDelta = a.heading - b.heading;
  if (headingDelta < 0) {
    headingDelta += Math.PI * 2;
  }

  // TODO: counterclockwise?
  let heading = lerp(a.heading, b.heading, t);
  let speed = lerp(a.speed, b.speed, t);
  return {
    x: Math.cos(heading) * speed,
    y: Math.sin(heading) * speed,
  };
}

const airVector = function(air, alt) {
  if (alt < 10000) {
    return airVectorAB(air.SE, air.FL100, alt / 10000);
  } else {
    return airVectorAB(air.FL100, air.FL400, (alt - 10000) / 30000);
  }
}

// elevation in feet
const airspeedToGroundVector = function(air, heading, airspeed, alt) {
  // linear mapping: FL400 기준 240kt -> 520 knots
  // 추가 고려 필요: 바람? 기압? https://en.wikipedia.org/wiki/Flight_level

  const fl400_mult = (520 / 240) - 1;
  const fl = alt / 100;
  // SE에서 1, FL400에서 fl400_mult
  const groundspeed = (1 + (fl400_mult * fl / 400)) * airspeed;
  const airvector = airVector(air, alt);

  return {
    x: Math.sin(heading) * groundspeed + airvector.x,
    y: Math.cos(heading) * groundspeed + airvector.y,
  };
}

class Flight {
  constructor(ty) {
    const callsign = randomSelect(CALLSIGNES);
    const number = Math.floor(randomRange(100, 999));

    const spec = PLANE_SPEC[ty];

    // 0..2pi 사이에서 spawn되고, normal에서 += pi/4만큼의 방향으로 움직임
    const spawnHeading = randomAngle();
    const flyBaseHeading = spawnHeading + Math.PI;
    const headingectionDeviation = randomRange(Math.PI / -4, Math.PI / 4);

    let flyHeading = flyBaseHeading + headingectionDeviation;
    while (flyHeading >= Math.PI * 2) {
      flyHeading -= Math.PI * 2;
    }

    this.state = 'CruiseApproach';
    this.name = `${callsign}${number}`;

    this.pos = {
      x: Math.sin(spawnHeading) * spec.spawn_horizon,
      y: Math.cos(spawnHeading) * spec.spawn_horizon,
    };

    // cruise airspeed: 240 kt, mach 0.78
    this.heading = flyHeading;
    this.airspeed = spec.cruise_airspeed;
    this.alt = spec.cruise_alt;
    this.ty = ty;

    this.navtarget_ty = 'arrival';
    this.payload_ty = 'cargo';

    // TODO
    this.runway = null;
    this.waypoint = null;

    this.ground_state = null;
    this.controls = { airspeed_offset: 0 };
  }

  setWaypoint(runway) {
    this.runway = runway;
    this.waypoint = this.nextWaypoint(this);
  }

  setNextWaypoint() {
    this.waypoint = this.nextWaypoint(this.waypoint);
  }

  nextWaypoint(waypoint) {
    const waypoints = this.runway.waypoints[`${this.ty}-${this.navtarget_ty}`];
    const dir = this.navtarget_ty === 'arrival' ? -1 : 1;
    return nearestWaypoint(waypoint, waypoints, dir);
  }

  markDivert() {
    this.state = 'Divert';
    // ad-hoc waypoint
    this.waypoint = spawnDivertWaypoint(this.ty);
  }

  markDepart() {
    // TODO: naming
    this.state = 'Depart';
    // ad-hoc waypoint
    this.waypoint = spawnDivertWaypoint(this.ty);
  }

  markLand(elapsed) {
    this.state = 'Landed';
    this.landed_at = elapsed;
    this.airspeed = 0;
    this.altitude = 0;
  }
}

function spawnFlight(ty) {
  return new Flight(ty);
}

function spawnFlightLanded(ty) {
  const plane = spawnFlight(ty);
  plane.markLand(1);
  return plane;
}

const speedToString = function(speed) {
  const mach = speed * KNOTS_TO_MACH;
  if (mach > 0.5) {
    return `mach ${mach.toFixed(2)}`;
  } else {
    return `${Math.floor(speed)}kt`;
  }
}

const FlightDesc = function(props) {
  const p = props.plane;
  const { className, air, onDivert, onGoAround, onSpeedControl } = props;

  const dist = Math.sqrt(p.pos.x * p.pos.x + p.pos.y * p.pos.y);

  const vec = airspeedToGroundVector(air, p.heading, p.airspeed, p.alt);
  const groundspeed = Math.sqrt(vec.x * vec.x + vec.y * vec.y);

  let payloadTy = <span className={`ty-${p.payload_ty}`}>{p.payload_ty}</span>;

  let divertbtn = null;
  if (p.waypoint.tag !== 'divert' && onDivert) {
    divertbtn = <button onClick={() => onDivert(p)}>divert</button>;
  }
  let goaroundbtn = null;
  if (p.state === 'Final' && onGoAround) {
    goaroundbtn = <button onClick={() => onGoAround(p)}>go around</button>;
  }

  let speedbtn = null;
  if (onSpeedControl) {
    speedbtn = <>
      <button onClick={() => onSpeedControl(p, 5)}>+5kt</button>
      <button onClick={() => onSpeedControl(p, -5)}>-5kt</button>
    </>;
  }

  // pos=({p.pos.x.toFixed(1)}nm, {p.pos.y.toFixed(1)}nm)
  return <div className={`box ${className ?? ''}`}>
    <span>
      <FlightName plane={p}/> {p.ty} {payloadTy} {p.state} target={p.navtarget_ty}
      {divertbtn} {goaroundbtn} {speedbtn}
    </span>
    dist={dist.toFixed(2)}nm alt={p.alt.toFixed(0)}ft, hdg={Math.floor(p.heading * 180 / Math.PI)}<br/>
    airspeed={speedToString(p.airspeed)}, ground={speedToString(groundspeed)}
      , control={speedToString(p.controls.airspeed_target)}/{speedToString(p.controls.airspeed_offset)}<br/>
  </div>;
};

function spawnDivertWaypoint(ty) {
  const spec = PLANE_SPEC[ty];

  const dist = spec.spawn_horizon * 2;
  const heading = randomAngle();
  const pos = {
    x: Math.sin(heading) * dist,
    y: Math.cos(heading) * dist,
  };
  return {
    name: '',
    dist: 80,
    pos,
    alt: spec.divert_alt,
    airspeed: spec.cruise_airspeed,
    margin: 1,
    tag: 'divert',
  };
}

function spawnWaypoint(origin, dist, alt, airspeed, heading, margin, tag) {
  const x = origin.x + Math.sin(heading) * dist;
  const y = origin.y + Math.cos(heading) * dist;
  return {
    name: '',
    dist,
    pos: { x, y },
    alt,
    airspeed,
    margin: margin * airspeed / 100,
    tag: tag ?? null,
  };
}

// approach distance based on maximum descent rate, which is often higher than standard descent rate, 5%
function calculateApproachDistance(delta_alt, descent_rate, v1, v2) {
  const v_avg = (v1 + v2) / 2;
  const descent_time = delta_alt / descent_rate;
  return v_avg * descent_time * KNOTS_TO_NM;
}

function spawnWaypointFromRef(spec, ref, alt, airspeed, heading, margin, tag) {
  const delta_alt = alt - ref.alt;
  // const dist = calculateApproachDistance(delta_alt, spec.climb_rate, ref.airspeed, airspeed);
  // descent rate: 3 degress, 5%
  // delta_alt * FEETS_TO_NM / dist = 0.05
  const dist = delta_alt * FEETS_TO_NM / spec.descent_rate;
  // console.log('dist', delta_alt, airspeed, dist, heading, ref.pos, delta_alt / (dist / FEETS_TO_NM));

  const x = ref.pos.x + Math.sin(heading) * dist;
  const y = ref.pos.y + Math.cos(heading) * dist;
  return {
    name: '',
    dist,
    pos: { x, y },
    alt,
    airspeed,
    margin,
    tag: tag ?? null,
    parent: ref,
  };
}

const calculateLandingWaypoints = function(spec, runway, iaf_count) {
  const { airspeed_final, cruise_airspeed } = spec;
  const heading = runway.heading + Math.PI;
  const waypoint_touch = spawnWaypoint(runway.pos, runway.length / 3 * FEETS_TO_NM, 100, airspeed_final - 10, heading, 0.2);
  const waypoint_0 = spawnWaypoint(runway.pos, runway.length * FEETS_TO_NM, 200, airspeed_final, heading, 0.2);

  const waypoint_final = spawnWaypointFromRef(spec, waypoint_0, 2000, (airspeed_final + cruise_airspeed) / 2, heading, 0.3, 'FINAL');

  const waypoints = [
    waypoint_touch,
    waypoint_0,
    waypoint_final,
  ];

  const add_iaf = (iaf_heading) => {
    waypoints.push(spawnWaypointFromRef(spec, waypoint_final, 4500, cruise_airspeed, iaf_heading, 0.3, 'IAF'));
  };

  if (iaf_count === 1) {
    add_iaf(heading);
  } else if (iaf_count === 2) {
    add_iaf(heading - Math.PI/3);
    add_iaf(heading + Math.PI/3);
  } else {
    let iaf_heading = heading - Math.PI/2;
    const step = Math.PI / (iaf_count - 1);
    for (let i = 0; i < iaf_count; i++) {
      add_iaf(iaf_heading);
      iaf_heading += step;
    }
  }

  return waypoints;
}

function spawnRandomRunway() {
  const max_offset = 0.5;
  const pos = { x: randomRange(-max_offset, max_offset), y: randomRange(-max_offset, max_offset) };
  const heading = randomAngle();
  return spawnRunway(pos, heading);
}

function spawnParallelRunway(runway, dist) {
  const heading = runway.heading;
  const normal = heading + Math.PI / 2;

  // in nmi
  const pos = {
    x: runway.pos.x + Math.sin(normal) * dist,
    y: runway.pos.y + Math.cos(normal) * dist,
  };

  return spawnRunway(pos, heading);
}

function attachWaypoints(runway, iaf_count) {
  const waypoints = {};

  for (const ty in PLANE_SPEC) {
    const spec = PLANE_SPEC[ty];
    if (spec.min_runway_length > runway.length) {
      continue;
    }

    waypoints[`${ty}-arrival`] = calculateLandingWaypoints(spec, runway, iaf_count);
    waypoints[`${ty}-departure`] = [ spawnWaypoint(runway.pos, 2, 2000, spec.airspeed_final, runway.heading, 0.3, 'Depart') ];
  }
  runway.waypoints = waypoints;
}

function spawnRunway(pos, heading) {
  let num = Math.floor(heading * 180 / Math.PI / 10).toString();
  if (num.length === 1) {
    num = '0' + num;
  }

  const name = `RWY${num}`;
  const runway = {
    idx: 0,
    level: 0,

    pos,
    heading,
    name,
    // in ft. ref: ORD: 8000x200, PAO: 2443x70
    length: 2443,
    // in ft
    width: 70,
  };
  attachWaypoints(runway, 1);

  return runway;
}

function nearestWaypoint(src, waypoints, dir) {
  let next = null;
  for (const candidate of waypoints) {
    if (dir*candidate.alt <= dir*src.alt) {
      continue;
    }
    if (!next) {
      next = candidate;
      continue;
    }

    if (dir*candidate.alt > dir*next.alt) {
      continue;
    }

    if (dir*candidate.alt < dir*next.alt || v2dist(src.pos, candidate.pos) < v2dist(src.pos, next.pos)) {
      next = candidate;
    }
  }
  return next;
}

function occupyingFlights(runway, planes) {
  return planes.filter((p) => p.runway === runway && (p.state === 'TakeOff' || p.state === 'FINAL'));
}

const UpgradeBtn = function(props) {
  const { model, label, args } = props;
  const { price, available, callback } = model;
  let args2 = [...(args ?? []) , price]
  return <button disabled={!available} onClick={() => callback(...args2)}>{label} ${price}</button>;
}

class Runway extends React.Component {
  render() {
    const { runway, planes, runwayUpgrade } = this.props;

    const flights = occupyingFlights(runway, planes);
    let info = <span>비어있습니다.</span>;
    if (flights.length > 0) {
      info = <>
        {flights.map((f) => <FlightName key={f.name} plane={f}/>)}
      </>;
    }

    return <>
      <span>runway {runway.name}
        <> | </>{runway.length}ft x {runway.width}ft
        <> | </><UpgradeBtn model={runwayUpgrade} label="upgrade" args={[runway]}/>
        <> | </>{info}</span>
    </>;
  }
}

class Runways extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: 1,
    };
  }

  render() {
    const { runways, planes, runwayBuild, runwayUpgrade } = this.props;

    return <div className="box">
      <h1>Runways</h1>
      {runways.map(runway => <Runway key={runway.name} runway={runway} planes={planes}
        runwayUpgrade={runwayUpgrade}/>)}
      <UpgradeBtn model={runwayBuild} label="build new runway" args={[]}/>
    </div>;
  }
}

class GroundOperation extends React.Component {
  constructor(props) {
    super(props);

    const p = props.plane;
    const spec = PLANE_SPEC[p.ty];

    this.ref = React.createRef();
    this.state = p.ground_state ?? {
      last_elapsed: props.elapsed,
      ticks: 0,

      ty: 'Landed',
      local_progress: 0,
      cur: 0,
      capacity: spec.capacity,
    };
  }

  componentDidUpdate(_prevProps, _prevState, _snapshot) {
    const { elapsed, onEarn, u } = this.props;
    const { handling_reward_level, handling_speed_level } = u;
    const dt = elapsed - this.state.last_elapsed;
    if (dt === 0) {
      return;
    }

    if (this.state.ty === 'Landed') {
      this.setState({ last_elapsed: elapsed });
      return;
    }

    let ticks = this.state.ticks + dt;
    let { local_progress, cur, capacity } = this.state;

    let tick_per_op = Math.floor(TICK_PER_GROUND_OP * Math.pow(0.8, handling_speed_level - 1));
    while (ticks > 0 && cur < capacity) {
      const amount = Math.min(ticks, tick_per_op - local_progress);

      local_progress += amount;
      ticks -= amount;

      if (local_progress >= tick_per_op) {
        cur += 1;
        local_progress = 0;

        const multiplier = 1 + (handling_reward_level - 1) * 0.2;
        onEarn(this.ref.current, BASE_REWARD_PER_HANDLE * multiplier);
      }
    }

    this.setState({
      last_elapsed: elapsed,
      ticks,

      local_progress,
      cur,
    });
  }

  onDisembark() {
    if (this.state.ty !== 'Landed') {
      return;
    }

    this.setState({
      ty: 'Disembark',
      ticks: 0,
      local_progress: 0,
      cur: 0,
    });
  }

  onEmbark() {
    if (this.state.ty !== 'Disembark') {
      return;
    }

    this.setState({
      ty: 'Embark',
      ticks: 0,
      local_progress: 0,
      cur: 0,
    });
  }

  renderProgress() {
    const { cur, capacity, local_progress } = this.state;
    const ratio = local_progress * 100 / TICK_PER_GROUND_OP;
    return <span ref={this.ref}>
      {cur}/{capacity} {ratio.toFixed(0)}%
    </span>;
  }

  render() {
    const { plane, onLoad, unoccupiedRunway, u } = this.props;
    const { auto_handling, auto_take_off } = u;

    const landed_ts = new Date(START_TS.getTime() + plane.landed_at * 1000);
    let btn = null;

    const enabled = this.state.capacity === this.state.cur;
    if (this.state.ty === 'Landed') {
      btn = <button onClick={() => this.onDisembark()}>disembark</button>;

      if (auto_handling) {
        this.onDisembark();
      }
    } else if (this.state.ty === 'Disembark') {
      if (enabled) {
        btn = <button onClick={() => this.onEmbark()}>embark</button>;

        if (auto_handling) {
          this.onEmbark();
        }
      } else {
        btn = <>
          {this.renderProgress()}
          <button disabled>disembarking</button>
        </>;
      }
    } else if (this.state.ty === 'Embark') {
      if (enabled) {
        let runway = unoccupiedRunway(plane);
        if (runway) {
          btn = <button onClick={(ev) => onLoad(ev.target, plane)}>take off</button>;
          if (auto_take_off) {
            onLoad(null, plane);
          }
        } else {
          btn = <button disabled>could not take off: runway occupied</button>;
        }
      } else {
        btn = <>
          {this.renderProgress()}
          <button disabled>embarking</button>
        </>;
      }
    }

    return <div><FlightName plane={plane}/> landed={landed_ts.toISOString()}, state={this.state.ty} {btn}</div>;
  }
}

class CargoTerminal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { planes, unoccupiedRunway, onLoad, onEarn, elapsed, u,
      cargoAutoHandle, cargoAutoTakeOff
    } = this.props;

    return <div className="box">
      <span>Cargo Terminal</span>
      <UpgradeBtn model={cargoAutoHandle} label="auto handle"/>
      <UpgradeBtn model={cargoAutoTakeOff} label="auto takeoff"/>

      <CapacityView cur={planes.length} max={u.terminal_capacity} label="capacity"/>
      {planes.map((p) => <GroundOperation key={p.name} plane={p}
        onEarn={onEarn} unoccupiedRunway={unoccupiedRunway} onLoad={onLoad}
        elapsed={elapsed} u={u}
        />)}
    </div>;
  }
}

const CapacityView = (props) => {
  const { label, cur, max } = props;
  const capacity_style = cur === max ? 'capacity capacity-full' : 'capacity';

  return <div className={capacity_style}>{label}={cur}/{max}</div>;
}

const FlightName = (props) => {
  let { plane } = props;
  return <FlightOverContext.Consumer>
    {({flight_selected, selectFlight}) => {
      let cls = "flight-name";
      if (plane === flight_selected) {
        cls += " flight-name-highlight";
      }
      return <span className={cls}
        onMouseEnter={() => selectFlight(plane)}
        onMouseLeave={() => selectFlight(null)}>
        항공편 {plane.name}
      </span>
    }}
  </FlightOverContext.Consumer>;
}

const JournalItemView = (props) => {
  const { elapsed, ev } = props;
  const dt = elapsed - ev.elapsed;
  const { ty } = ev;

  let body = JSON.stringify(ev);
  let className = 'journal-item';
  if (ty === 'divert-seperation') {
    const { plane, other } = ev;
    body = <><FlightName plane={plane}/>이 주변 공항으로 우회합니다: 다른 항공편 {other.name}과 너무 가까워 안전하지 않습니다.</>;
  } else if (ty === 'divert-terminal-full') {
    const { plane } = ev;
    body = <><FlightName plane={plane}/>이 결심고도까지 접근했지만 빈 터미널이 없습니다, 주변 공항으로 우회합니다.</>;
  } else if (ty === 'spawn') {
    const { plane } = ev;
    body = <><FlightName plane={plane}/>이 착륙을 위해 공항에 접근합니다.</>;
  } else if (ty === 'spawn-blocked') {
    const { plane } = ev;
    className += ' journal-item-info';
    body = <>공역이 혼잡합니다. <FlightName plane={plane}/>이 공항에 접근하지 못하고 우회합니다.</>;
  } else if (ty === 'arrival') {
    const { plane } = ev;
    body = <><FlightName plane={plane}/> 이 착륙합니다.</>;
  } else if (ty === 'departure') {
    const { plane } = ev;
    body = <><FlightName plane={plane}/> 이 이륙합니다.</>;
  }
  let reltime = `${dt}초 전`;
  if (dt > 60) {
    reltime = `${Math.floor(dt/60)}분 전`;
  }

  return <div className={className}>{reltime}:{body}</div>;
};

class Airspace extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
    };
  }

  componentDidMount() {
    this.renderCanvas();
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  worldToCanvas(world) {
    const { canvasSize, range } = this.props;
    const abs = world * canvasSize / range / 2;
    return abs + canvasSize / 2;
  }

  renderCanvas() {
    const { runways, planes, flight_selected, canvasSize, range } = this.props;
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');

    // background
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvasSize, canvasSize);

    // border
    ctx.strokeStyle = 'white';
    ctx.beginPath();
    ctx.arc(canvasSize/2, canvasSize/2, canvasSize/2, 0, 2 * Math.PI);
    ctx.stroke();

    const rotate = function(pos, heading) {
      // matrix multiplication...
      const dx = Math.sin(heading) * pos.x + Math.cos(heading) * pos.y;
      const dy = -1 * Math.cos(heading) * pos.x + Math.sin(heading) * pos.y;
      return { x: dx, y: dy };
    }

    const renderRunwayGeom = function(x, y, length, width, heading) {

      const centerX = this.worldToCanvas(x);
      const centerY = this.worldToCanvas(-1 * y);
      const dx = length * canvasSize / range / 2;
      const dy = width * canvasSize / range / 3;

      ctx.strokeStyle = 'red';
      ctx.beginPath();
      let p = rotate({x: dx, y: dy}, heading);
      ctx.moveTo(centerX + p.x, centerY + p.y);
      p = rotate({x: -1 * dx, y: dy}, heading);
      ctx.lineTo(centerX + p.x, centerY + p.y);
      p = rotate({x: -1 * dx, y: -1 * dy}, heading);
      ctx.lineTo(centerX + p.x, centerY + p.y);
      p = rotate({x: dx, y: -1 * dy}, heading);
      ctx.lineTo(centerX + p.x, centerY + p.y);
      p = rotate({x: dx, y: dy}, heading);
      ctx.lineTo(centerX + p.x, centerY + p.y);
      ctx.stroke();
    }

    // DME
    const renderSymbolHexagon = function(canvasX, canvasY, size) {
      ctx.beginPath();
      let moved = false;
      for (let i = 0; i < 7; i++) {
        // hexagon
        const angle = (30 + i * 60) * Math.PI / 180;
        let x = canvasX + Math.sin(angle) * size;
        let y = canvasY + Math.cos(angle) * size;
        if (!moved) {
          ctx.moveTo(x, y);
          moved = true;
        } else {
          ctx.lineTo(x, y);
        }
      }
      ctx.stroke();
    }

    const renderSymbolStar = function(canvasX, canvasY, size) {
      ctx.beginPath();
      const points = [
        {x: canvasX + size, y: canvasY },
        {x: canvasX, y: canvasY - size },
        {x: canvasX - size, y: canvasY },
        {x: canvasX, y: canvasY + size },
      ];

      let last = points[points.length - 1];
      ctx.moveTo(last.x, last.y);
      for (let i = 0; i < 4; i++) {
        const p = points[i];
        const cp = 0.7;
        ctx.bezierCurveTo(
          lerp(canvasX, last.x, cp), lerp(canvasY, last.y, cp),
          lerp(canvasX, p.x, cp), lerp(canvasY, p.y, cp),
          p.x, p.y);
        last = p;
      }

      ctx.stroke();

      ctx.beginPath();
      ctx.arc(canvasX, canvasY, size / 2, 0, Math.PI*2);
      ctx.stroke();
    }

    const renderWaypoint = function(waypoint) {
      ctx.strokeStyle = 'lightblue';
      ctx.fillStyle = 'lightblue';

      const canvasX = this.worldToCanvas(waypoint.pos.x);
      const canvasY = this.worldToCanvas(-1 * waypoint.pos.y);

      // renderSymbolHexagon(canvasX, canvasY, 5);
      renderSymbolStar(canvasX, canvasY, 6);

      ctx.font = '12px monospace';
      ctx.fillText(waypoint.name, canvasX, canvasY);
    }


    for (let i = 0; i < runways.length; i++) {
      const runway = runways[i];

      const centerX = this.worldToCanvas(runway.pos.x);
      const centerY = this.worldToCanvas(-1 * runway.pos.y);

      // runway geom
      // renderRunwayGeom.bind(this)(0, 0, 10, 1, runway.heading);
      renderRunwayGeom.bind(this)(runway.pos.x, runway.pos.y,
        runway.length * FEETS_TO_NM, runway.width * FEETS_TO_NM, runway.heading);

      // centerline
      {
        ctx.strokeStyle = 'gray';
        ctx.beginPath();
        let p = rotate({x: -150, y: 0}, runway.heading);
        ctx.moveTo(centerX + p.x, centerY + p.y);
        p = rotate({x: 150, y: 0}, runway.heading);
        ctx.lineTo(centerX + p.x, centerY + p.y);
        ctx.stroke();
      }

      // virtual waypoint
      for (const ty in runway.waypoints) {
        const waypoints = runway.waypoints[ty];
        for (let j = 0; j < waypoints.length; j++) {
          renderWaypoint.bind(this)(waypoints[j]);
        }
      }

      ctx.fillStyle = 'red';
      ctx.font = '14px monospace';
      ctx.fillText(runway.name, centerX, centerY + 20);
    }

    for (let i = 0; i < planes.length; i++) {
      const p = planes[i];
      const spec = PLANE_SPEC[p.ty];
      const canvasX = this.worldToCanvas(p.pos.x);
      const canvasY = this.worldToCanvas(-1 * p.pos.y);
      const size = 10;

      if (p === flight_selected) {
        ctx.fillStyle = 'purple';
      } else if (p.waypoint.tag === 'divert') {
        ctx.fillStyle = 'gray';
      } else {
        ctx.fillStyle = spec.canvas_color;
      }

      ctx.fillRect(canvasX-size/2, canvasY-size/2, size, size);

      ctx.font = '12px monospace';
      ctx.fillText(p.name, canvasX + size / 2, canvasY);

      if (p.controls.airspeed_offset) {
        ctx.font = '10px monospace';
        const sign = p.controls.airspeed_offset > 0 ? '+' : '';
        ctx.fillText(`${sign}${p.controls.airspeed_offset}kt`, canvasX + size / 2, canvasY + 10);
      }
    }

    if (flight_selected && flight_selected.waypoint.tag !== 'divert') {
      // render path
      let obj = flight_selected;

      ctx.strokeStyle = 'white';
      ctx.beginPath();
      ctx.moveTo(this.worldToCanvas(obj.pos.x), this.worldToCanvas(-1 * obj.pos.y));
      obj = flight_selected.waypoint;
      while (obj !== null) {
        ctx.lineTo(this.worldToCanvas(obj.pos.x), this.worldToCanvas(-1 * obj.pos.y));
        obj = flight_selected.nextWaypoint(obj);
      }
      ctx.stroke();
    }

    ctx.fillStyle = 'white';
    ctx.font = '14px monospace';
    ctx.fillText(`${range}nm`, 5, 20);
  }

  projectEventToFlight(e) {
    const { planes } = this.props;
    const c = this.canvasRef.current;
    const rect = c.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    for (const p of planes) {
      const canvasX = this.worldToCanvas(p.pos.x);
      const canvasY = this.worldToCanvas(-1 * p.pos.y);

      const dx = mouseX - canvasX;
      const dy = mouseY - canvasY;
      const dist = Math.sqrt(dx*dx + dy*dy);
      if (dist < 20) {
        return p;
      }
    }
    return null;
  }

  canvasMouseOver(e) {
    const { selectFlight } = this.props;
    const p = this.projectEventToFlight(e);
    selectFlight(p);
  }

  canvasMouseDown(e) {
    e.preventDefault();

    const { onSpeedControl } = this.props;
    const p = this.projectEventToFlight(e);
    if (!p) {
      return;
    }

    if (e.button === 0) {
      // left click
      onSpeedControl(p, -5);
    } else if (e.button === 1) {
      // right click
      onSpeedControl(p, 0);
    } else if (e.button === 2) {
      // middle click
      onSpeedControl(p, 5);
    }
  }

  renderJournal() {
    const { journal, elapsed } = this.props;
    let items = journal.map((ev, i) => <JournalItemView key={i} ev={ev} elapsed={elapsed}/>);
    items.reverse();
    return items;
  }

  render() {
    const { air, runways, planes, planes_landed, flight_selected,
      airspace_arrival_usage, u,
      unoccupiedRunway,
      onDivert, onGoAround, onTakeOff, onEarn, onSpeedControl,
      runwayBuild, runwayUpgrade,
      cargoAutoHandle, cargoAutoTakeOff,
      canvasSize, elapsed } = this.props;

    let planeover = null;
    if (flight_selected) {
      let plane = flight_selected;
      planeover = <FlightDesc className="box-gray" key={plane.name} plane={plane} air={air}/>;
    } else {
      planeover = <div className="box">
        <div>항공편이 선택되지 않았습니다.</div>
        <div>empty</div>
        <div>empty</div>
      </div>;
    }

    return <div className="box">
      <h1>airspace FML</h1>
      <CapacityView cur={airspace_arrival_usage} max={u.airspace_capacity} label="arrival capacity"/>

      <div className="split">
        <canvas className="airvis" width={canvasSize} height={canvasSize}
          ref={this.canvasRef}
          onContextMenu={(e) => e.preventDefault()}
          onMouseMove={(e) => this.canvasMouseOver(e)}
          onMouseDown={this.canvasMouseDown.bind(this)}
        ></canvas>
      <div>
      {this.renderJournal()}
      </div>
      </div>

      <Runways planes={planes} runways={runways}
        runwayBuild={runwayBuild} runwayUpgrade={runwayUpgrade}/>

      <CargoTerminal planes={planes_landed}  elapsed={elapsed} u={u}
        unoccupiedRunway={unoccupiedRunway} cargoAutoHandle={cargoAutoHandle} cargoAutoTakeOff={cargoAutoTakeOff}
        onLoad={onTakeOff} onEarn={onEarn}/>

      {planeover}

      {planes.map(plane => <FlightDesc key={plane.name} plane={plane} air={air}
        onDivert={onDivert} onGoAround={onGoAround} onSpeedControl={onSpeedControl}
        />)}
    </div>;
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.testRef = React.createRef();
    this.keyBind = this.keyDown.bind(this);
    this.state = this.initialState();
  }

  initialState() {
    const elapsed = 0;
    const journal = [];
    const planes = [];

    const runway = spawnRandomRunway();
    const runways = [runway];

    for (let i = 0; i < 1; i++) {
      const plane = spawnFlight('prop');
      plane.setWaypoint(runway);

      planes.push(plane);
      journal.push({
        elapsed,
        ty: 'spawn',
        plane: plane,
      });
    }

    const planes_landed = [];
    for (let i = 0; i < 1; i++) {
      planes_landed.push(spawnFlightLanded('prop'));
    }

    const lv = DEBUG ? 5 : 1;

    return {
      elapsed,
      tps: DEBUG ? 60 : 10,
      pause: false,
      range: 10,
      planes,
      planes_landed,
      runways,
      air: createAir(),

      // upgrades, persistent state
      u: {
        airspace_capacity: lv,
        terminal_capacity: lv,
        arrival_rate_level: lv,
        handling_reward_level: lv,
        handling_speed_level: 1,
        iaf_count: 1,

        auto_handling: DEBUG,
        auto_take_off: DEBUG,
      },

      resource: 100,

      journal,

      // highlighted
      flight_selected: null,
      selectFlight: (p) => {
        if (this.state.flight_selected === p) {
          return;
        }
        if (this.state.planes.indexOf(p) === -1) {
          // not flying
          this.setState({ flight_selected: null });
          return;
        }
        this.setState({ flight_selected: p });
      }
    };
  }

  componentDidMount() {
    this.setTimer();

    document.addEventListener('keydown', this.keyBind);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;

    document.removeEventListener('keydown', this.keyBind);
  }

  keyDown(ev) {
    if (ev.key === 'Tab') {
      ev.preventDefault();

      const curIdx = TPS_OPTS.indexOf(this.state.tps) ?? 0;
      const nextIdx = (curIdx + 1) % TPS_OPTS.length;
      this.setState({tps: TPS_OPTS[nextIdx]});
    } else if (ev.key === 'r') {
      this.setState(this.initialState());
    } else if (ev.key === 's') {
      const planes = this.state.planes.slice(0);
      const plane = spawnFlight('prop1');
      plane.setWaypoint(this.state.runways[0]);

      planes.push(plane);
      this.setState({
        planes,
      });
    } else if (ev.key === 'z') {
      ev.preventDefault();

      const curIdx = RANGES.indexOf(this.state.range);
      const nextIdx = (curIdx + 1) % RANGES.length;
      this.setState({range: RANGES[nextIdx]});
    } else if (ev.key === 'Z') {
      ev.preventDefault();

      const curIdx = RANGES.indexOf(this.state.range);
      const nextIdx = (curIdx + RANGES.length - 1) % RANGES.length;
      this.setState({range: RANGES[nextIdx]});
    } else if (ev.key === ' ') {
      ev.preventDefault();
      this.setState({ pause: !this.state.pause });
    } else {
      console.log('keyev', ev);
    }
  }

  setTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.state.tps > 0 && !this.state.pause) {
      this.timer = setInterval(this.onTick.bind(this), 1000 / this.state.tps);
    }
  }

  componentDidUpdate(_prevProps, prevState, _snapshot) {
    if (this.state.tps !== prevState.tps || this.state.pause !== prevState.pause) {
      this.setTimer();
    }
  }

  airspaceUsage() {
    return this.state.planes.filter((p) => p.waypoint?.tag !== 'divert' && p.state !== 'TakeOff').length;
  }

  onTick() {
    let { air, elapsed, planes, planes_landed, runways, flight_selected, u } = this.state;
    const arrival_rate = 0.004 * Math.pow(1.2, u.arrival_rate_level - 1);

    if (randomRange(0, 1) < arrival_rate) {
      // select runway
      const runway = randomChoice(runways);

      // select type
      const types = Object.keys(PLANE_SPEC).filter((ty) => PLANE_SPEC[ty].min_runway_length <= runway.length);
      const ty = randomChoice(types);
      planes = planes.slice(0);

      const plane = spawnFlight(ty);
      plane.setWaypoint(runway);
      if(this.airspaceUsage() < u.airspace_capacity) {
        planes.push(plane);
        this.onEvent({
          elapsed,
          ty: 'spawn',
          plane: plane,
        });
      } else {
        this.onEvent({
          elapsed,
          ty: 'spawn-blocked',
          plane: plane,
        });
      }
    }

    planes_landed = planes_landed.slice(0);

    const seperated = function(p1, p2) {
      // vertical seperation: 1000 ft
      // horizontal seperation: 1nm

      if (Math.abs(p1.alt - p2.alt) > 1000) {
        return true;
      }

      let dx = p1.pos.x - p2.pos.x;
      let dy = p1.pos.y - p2.pos.y;

      if (Math.abs(dx * dx + dy * dy) > 1) {
        return true;
      }
      return false;
    }

    planes = planes.map((p) => {
      const spec = PLANE_SPEC[p.ty];

      if (p.state === 'FINAL' && planes_landed.length >= u.terminal_capacity) {
        // capacity full, divert
        p.markDivert();

        this.onEvent({
          elapsed,
          ty: 'divert-terminal-full',
          plane: p,
        });
      }

      // seperation violation
      for (const p2 of planes) {
        if (p === p2 || p.state === 'Divert' || p2.state === 'Divert') {
          // do not check seperation violation on diverted planes
          continue;
        }

        if (!seperated(p, p2)) {
          this.onEvent({
            elapsed,
            ty: 'divert-seperation',
            plane: p,
            other: p2,
          });
          p.markDivert();
          break;
        }
      }

      // navigation
      const { pos } = p;
      if (v2length(pos) > spec.spawn_horizon + 5) {
        // untrack
        return null;
      }

      const a = { x: 0, y: 0 };

      const waypoint = p.waypoint;
      let target = waypoint.pos;

      const vec = airspeedToGroundVector(air, p.heading, p.airspeed, p.alt);
      const speed = v2length(vec);
      const c = v2normalize(vec);
      let b = {
        x: target.x - pos.x,
        y: target.y - pos.y,
      };

      if (v2length(b) < waypoint.margin) {
        if (waypoint.tag) {
          p.state = waypoint.tag;
        }

        p.setNextWaypoint();
        if (!p.waypoint) {
          if (p.navtarget_ty === 'departure') {
            // departure, ad-hoc target
            p.markDepart();
          } else {
            // arrival, landing
            p.landed_at = elapsed;
            p.airspeed = 0;

            this.onEvent({
              elapsed,
              ty: 'arrival',
              plane: p,
            });

            const multiplier = 1 + (u.handling_reward_level - 1) * 0.2;
            this.onEarn(null, BASE_REWARD_PER_TAKEOFF_LAND * multiplier);

            planes_landed.push(p);
            return null;
          }
        }
      }

      b = v2normalize(b);

      let cross = (b.x - a.x)*(c.y - a.y) - (b.y - a.y)*(c.x - a.x);
      if (isNaN(cross)) {
        cross = 0;
      }

      // heading
      const adjust = spec.rot_mult * Math.PI / 180 * cross;
      p.heading += adjust;
      while (p.heading < 0) {
        p.heading += 2 * Math.PI;
      }
      while (p.heading > 2 * Math.PI) {
        p.heading -= 2 * Math.PI;
      }

      // altitude
      let climb = false;
      if (p.alt > waypoint.alt) {
        p.alt -= speed * KNOTS_TO_NM / FEETS_TO_NM * spec.descent_rate;
      } else if (p.alt < waypoint.alt && speed > spec.takeoff_airspeed) {
        // TODO: take-off speed?
        p.alt += spec.climb_rate;
        climb = true;
      }

      // airspeed
      let accel = 1;
      if (speed < spec.takeoff_airspeed) {
        accel = spec.takeoff_accel;
      }

      let airspeed_target = waypoint.airspeed + p.controls.airspeed_offset;
      if (airspeed_target < spec.takeoff_airspeed) {
        airspeed_target = spec.takeoff_airspeed;
      }
      if (airspeed_target >= spec.cruise_airspeed) {
        airspeed_target = spec.cruise_airspeed;
      }
      p.controls.airspeed_target = airspeed_target;

      if (p.airspeed > airspeed_target) {
        p.airspeed -= accel;
      } else if (p.airspeed < airspeed_target && !climb) {
        p.airspeed += accel;
      }

      p.pos = {
        x: pos.x + vec.x * KNOTS_TO_NM,
        y: pos.y + vec.y * KNOTS_TO_NM,
      };

      return p;
    }).filter((p) => p !== null);

    if (flight_selected) {
      flight_selected = planes.find((p) => p.name === flight_selected.name) ?? null;
    }

    this.setState({
      elapsed: elapsed + 1,
      planes,
      planes_landed,
      flight_selected,
    });
  }

  onGoAround(p) {
    const waypoints = p.runway.waypoints[p.navtarget_ty];
    p.waypoint = waypoints.find(w => w.tag === 'IAF');
    p.state = 'GoAround';
  }

  onSpeedControl(p, delta) {
    let planes = this.state.planes.slice(0);
    let idx = planes.indexOf(p);

    if (delta === 0) {
      // TODO: FIXME
      p.controls.airspeed_offset = 0;
    } else {
      p.controls.airspeed_offset += delta;
    }
    planes[idx] = p;
    this.setState({ planes });
  }

  onDivert(p) {
    p.markDivert();
  }

  unoccupiedRunway() {
    const { runways, planes } = this.state;

    for (const runway of runways) {
      const occupied = planes.filter((p) => p.runway === runway && (p.state === 'TakeOff' || p.state === 'FINAL')).length > 0;
      if (!occupied) {
        return runway;
      }
    }
    return null;
  }

  onTakeOff(target, p) {
    let { planes_landed, planes, elapsed, u } = this.state;

    planes_landed = planes_landed.slice(0).filter((plane) => plane.name !== p.name);

    let runway = this.unoccupiedRunway();

    p.state = "TakeOff";
    p.heading = runway.heading;
    p.pos = runway.pos;
    p.navtarget_ty = `departure`;
    p.alt = 0;
    p.setWaypoint(runway);
    p.controls = { airspeed_offset: 0 };

    planes = planes.slice(0);
    planes.push(p);

    this.onEvent({
      elapsed,
      ty: 'departure',
      plane: p,
    });

    this.setState({
      planes,
      planes_landed,
    });

    const multiplier = 1 + (u.handling_reward_level - 1) * 0.2;
    this.onEarn(target, BASE_REWARD_PER_TAKEOFF_LAND * multiplier);
  }

  onEarn(target, amount) {
    amount = Math.floor(amount);
    this.setState({
      resource: this.state.resource + amount,
    });

    if (!target) {
      target = this.testRef.current;
    }

    const rect = target.getBoundingClientRect();
    const text = `left: ${rect.left}px; top: ${rect.top}px;`;

    const elem = document.createElement('span');
    elem.innerText = `+$${amount}`;
    elem.className = 'earn';
    elem.style.cssText = text;

    document.body.appendChild(elem);

    setTimeout(() => {
      elem.remove();
    }, 1000);
  }

  onEvent(ev) {
    let { journal } = this.state;
    journal = journal.slice();
    journal.push(ev);
    if (journal.length > 20) {
      journal = journal.slice(journal.length - 20);
    }
    this.setState({ journal });
  }

  renderUpgrade(name, price, onClick) {
    const { resource } = this.state;
    if (this.state.resource < price) {
      return <button disabled>{name} ${price}</button>;
    }
    return <button onClick={() => {
      this.setState({resource: resource - price});
      onClick();
    }}>{name} ${price}</button>;
  }

  onDebugAddJournalItem() {
    let { journal } = this.state;
    journal = journal.slice(0);
    journal.push({
      elapsed: 0,
      ty: 'divert-seperation',
      plane: { name: "AA000" },
      other: { name: "BB000" },
    });
    this.setState({ journal });
  }

  runwayBuild() {
    const level = this.state.runways.length;
    const price = level === 1 ? 50000 : 99999999;
    return {
      price,
      available: price <= this.state.resource,
      callback: this.onRunwayBuild.bind(this),
    };
  }

  onRunwayBuild(price) {
    if (this.state.runways.length === 2) {
      return;
    }

    const runway = this.state.runways[0];
    runway.name = `${runway.name}L`;
    const runway2 = spawnParallelRunway(runway, 0.2);
    runway2.name = `${runway2.name}R`;
    const runways = [runway, runway2];

    this.setState({ runways, resource: this.state.resource - price });
  }

  runwayUpgrade() {
    const level = this.state.runways.reduce((acc, runway) => acc + runway.level, 0);
    const price = Math.floor(Math.pow(level + 1, 2) * 5000);
    return {
      price,
      available: price <= this.state.resource,
      callback: this.onRunwayUpgrade.bind(this),
    };
  }

  onRunwayUpgrade(runway, price) {
    const { u } = this.state;
    const next = { ...runway };
    next.level += 1;
    next.length = Math.floor(runway.length * 1.2);
    next.width += 20;
    attachWaypoints(next, u.iaf_count);

    let idx = this.state.runways.indexOf(runway);
    const runways = this.state.runways.slice(0);
    runways[idx] = next;

    this.setState({ runways, resource: this.state.resource - price });
  }

  cargoAutoHandle() {
    const { resource } = this.state;
    const price = 2000;
    return {
      price,
      available: !this.state.u.auto_handling && price <= resource,
      callback: () => {
        this.setState({ resource: this.state.resource - price });
        this.upgradeSet('auto_handling');
      },
    };
  }

  cargoAutoTakeOff() {
    const { resource } = this.state;
    const price = 1000;
    return {
      price,
      available: !this.state.u.auto_take_off & price <= resource,
      callback: () => {
        this.setState({ resource: this.state.resource - price });
        this.upgradeSet('auto_take_off');
      },
    };
  }

  upgradeSet(key) {
    let { u } = this.state;
    u = {...u};
    u[key] = true;
    this.setState({ u });
  }

  incr(key) {
    let { u } = this.state;
    u = {...u};
    u[key] += 1;
    this.setState({ u });
  }

  renderControl() {
    const { airspace_capacity, terminal_capacity, arrival_rate_level, handling_reward_level, handling_speed_level, iaf_count } = this.state.u;
    const cost = (level) => {
      return level * level * 100;
    };

    return <div>
      {this.renderUpgrade('+1 inbound capacity', cost(airspace_capacity), () => {
        this.incr('airspace_capacity');
      })}
      {this.renderUpgrade('+1 terminal capacity', cost(terminal_capacity), () => {
        this.incr('terminal_capacity');
      })}
      {this.renderUpgrade('20% arrival rate', cost(arrival_rate_level), () => {
        this.incr('arrival_rate_level');
      })}
      {this.renderUpgrade('+20% per handling', cost(handling_reward_level), () => {
        this.incr('handling_reward_level');
      })}
      {this.renderUpgrade('+20% handling speed', cost(handling_speed_level), () => {
        this.incr('handling_speed_level');
      })}
      {this.renderUpgrade('+1 IAF', cost(iaf_count) * 10, () => {
        let next_iaf_count = iaf_count + 1;
        const runways = this.state.runways.map((runway) => {
          runway = {...runway};
          attachWaypoints(runway, next_iaf_count);
          return runway;
        });
        this.incr('iaf_count');
        this.setState({ runways });
      })}

      {this.renderUpgrade('earn', 1, () => {
        this.onEarn(this.testRef.current, 1);
      })}
    </div>;
  }

  render() {
    const { tps, pause, elapsed, resource, flight_selected, selectFlight } = this.state;
    // console.log(START_TS, elapsed);
    const now = new Date(START_TS.getTime() + elapsed * 1000);

    return (
      <FlightOverContext.Provider value={{ flight_selected, selectFlight }}>
        <div className="App">
          <div className="box">
            <h1>resources & upgrades</h1>
            <div>balance: <span ref={this.testRef} className="resource">${resource}</span></div>
            {this.renderControl()}
          </div>
          <Airspace canvasSize={400} {...this.state}
            onEarn={this.onEarn.bind(this)}
            onDivert={this.onDivert.bind(this)}
            onGoAround={this.onGoAround.bind(this)}
            onSpeedControl={this.onSpeedControl.bind(this)}
            onTakeOff={this.onTakeOff.bind(this)}
            runwayBuild={this.runwayBuild()}
            runwayUpgrade={this.runwayUpgrade()}
            cargoAutoHandle={this.cargoAutoHandle()}
            cargoAutoTakeOff={this.cargoAutoTakeOff()}
            airspace_arrival_usage={this.airspaceUsage()}
            unoccupiedRunway={this.unoccupiedRunway.bind(this)}
            />
          <div>
            debug
            <div>tps={tps}, pause={pause} elapsed={elapsed}s, {now.toISOString()}</div>
            <button onClick={this.onDebugAddJournalItem.bind(this)}>add test journal</button>
          </div>
        </div>
      </FlightOverContext.Provider>
    );
  }
}

export default App;
